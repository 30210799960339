.toolbar[data-v-184b195f] {
  display: flex;
  flex-direction: var(--184b195f-flexDirection);
  justify-content: space-between;
}
.toolbar .tool_left[data-v-184b195f] {
  display: flex;
  flex-direction: var(--184b195f-flexDirection);
  flex-wrap: wrap;
  justify-content: flex-start;
}
.toolbar .tool_left .input_container[data-v-184b195f] {
  margin: 0 8px 8px 0;
}
.toolbar .tool_right[data-v-184b195f] {
  display: flex;
  flex-direction: var(--184b195f-flexDirection);
  flex-wrap: wrap;
}
.toolbar .tool_right .tool_dropdown_menu[data-v-184b195f] {
  margin: 0 20px 8px 0;
}
.toolbar .tool_right .tool_toggle_State[data-v-184b195f] {
  margin: 0 20px 8px 0;
}
