
[data-v-561cd847] .ag-group-contracted{
	height: 100% !important;
}
[data-v-561cd847] .ag-group-expanded{
	height: 100% !important;
}
[data-v-561cd847] .ag-group-child-count{
	height: 100% !important;
}
